(function($) {
    'use strict';
    $(document).on('show.bs.tab', '.nav-tabs-responsive [data-toggle="tab"]', function(e) {
        var $target = $(e.target);
        var $tabs = $target.closest('.nav-tabs-responsive');
        var $current = $target.closest('li');
        var $parent = $current.closest('li.dropdown');
        $current = $parent.length > 0 ? $parent : $current;
        var $next = $current.next();
        var $prev = $current.prev();
        var updateDropdownMenu = function($el, position) {
            $el
                .find('.dropdown-menu')
                .removeClass('pull-xs-left pull-xs-center pull-xs-right')
                .addClass('pull-xs-' + position);
        };
        $tabs.find('>li').removeClass('next prev');
        $prev.addClass('prev');
        $next.addClass('next');
        updateDropdownMenu($prev, 'left');
        updateDropdownMenu($current, 'center');
        updateDropdownMenu($next, 'right');
    });
})(jQuery);

$(document).ready(function() {

    $(function() {
        $('.modal').modal({
            show: false
        }).on('hidden.bs.modal', function() {
            $(this).find('video')[0].pause();
        });
       
    });
    
    $(function() {
        $('.modal').on('hidden.bs.modal', function(e) {
            $iframe = $(this).find("iframe");
            $iframe.attr("src", $iframe.attr("src"));
        });
    });


    // if ($("#Left-Nav").hasClass("is-active") == true) {
    //     $('.Main_Tag').addClass('Main_Tag_Active');
    //     $('#Left-Nav').removeClass('active');
    // }

    function spinGlobe() {
        var tmax_tl = new TimelineMax({
            delay: 0.1675,
            repeat: -1
        });
        var globe_continents = [
            $('#globe #middle g path'),
            $('#globe #left g path')
        ];
        var globe_speed = 2;
        var map_from = {
            x: 0
        };
        var map_to = {
            x: 150,
            ease: Linear.easeOut
        };
        tmax_tl.fromTo(globe_continents, globe_speed, map_from, map_to, 0);
        return tmax_tl;
    }

    spinGlobe();

    // PASSWORD VBISIBLE

    $('.Lang-Confirm-Dialog').on('change', function() {
        $('.modal').modal('show');
    });

    $('.tab-slider').owlCarousel({
        nav: false,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        margin: 20,
        loop: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 30,
                margin: 10,
            },
            412: {
                items: 2,
                nav: false,
                stagePadding: 30,
                margin: 10,
            },
            1000: {
                items: 3,
            }
        }
    });

    $('.isa-media-carousel').owlCarousel({
        autoplay: false,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: false,
        lazyLoad: true,
        margin: 20,
        video: true,
        responsive: {
            0: {
                items: 3,
                nav: false,
                stagePadding: 20,
                margin: 10,
                loop: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 3,
                loop: true,
                stagePadding: 20
            },
            1600: {
                items: 7,
                loop: true,
                stagePadding: 20
            }
        },
    });
    $('.media-Slider').owlCarousel({
        autoplay: false,
        nav: false,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: false,
        lazyLoad: true,
        video: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
            }
        },
    });

    $('#Side-Nav-Menu>li').click(function() {
        $('#Side-Nav-Menu>li').removeClass("Active_Menu");
        $(this).addClass("Active_Menu");
        //$("#Left-Nav").toggleClass("Desktop_Nav_Active");
    });
});


$('.row-icon').click(function() {
    $('.hiddenRow').toggleClass('.hiddenRow');
});

function updateOwlCarousel() {
    $('.tab-slider').owlCarousel({
        nav: false,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        margin: 20,
        loop: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 30,
                margin: 10,
            },
            412: {
                items: 2,
                nav: false,
                stagePadding: 30,
                margin: 10,
            },
            1000: {
                items: 3,
            }
        }
    });

    $('.isa-media-carousel').owlCarousel({
        autoplay: false,
        nav: true,
        navText: ["<i class='material-icons'>keyboard_arrow_left</i>", "<i class='material-icons'>keyboard_arrow_right</i>"],
        dots: false,
        lazyLoad: true,
        margin: 20,
        video: true,
        responsive: {
            0: {
                items: 3,
                nav: false,
                stagePadding: 20,
                margin: 10,
                loop: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 3,
                loop: true,
                stagePadding: 20
            },
            1600: {
                items: 7,
                loop: true,
                stagePadding: 20
            }
        },
    });


}

$(document).ready(function() {
    // $(function() {
    //     var open = false,
    //         duration = 0.9,
    //         timing = 'cubic-bezier(0.7, 0, 0.3, 1)';
    //     Moveit.put(first, {
    //         start: '0%',
    //         end: '14%',
    //         visibility: 1
    //     });
    //     Moveit.put(second, {
    //         start: '0%',
    //         end: '11.5% ',
    //         visibility: 1
    //     });
    //     Moveit.put(middle, {
    //         start: '0%',
    //         end: '100%',
    //         visibility: 1
    //     });

    //     function drawerToggle() {
    //         if (!open) {
    //             Moveit.animate(first, {
    //                 visibility: 1,
    //                 start: '78%',
    //                 end: '93%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //             Moveit.animate(middle, {
    //                 visibility: 1,
    //                 start: '50%',
    //                 end: '50%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //             Moveit.animate(second, {
    //                 visibility: 1,
    //                 start: '81.5%',
    //                 end: '94%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //         } else {
    //             Moveit.animate(middle, {
    //                 visibility: 1,
    //                 start: '0%',
    //                 end: '100%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //             Moveit.animate(middle, {
    //                 visibility: 1,
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //             Moveit.animate(first, {
    //                 visibility: 1,
    //                 start: '0%',
    //                 end: '14%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //             Moveit.animate(second, {
    //                 visibility: 1,
    //                 start: '0%',
    //                 end: '11.5%',
    //                 duration: duration,
    //                 delay: 0,
    //                 timing: timing
    //             });
    //         }
    //         open = !open;
    //     };
    // });

    $('.isa-media-modal').on('show.bs.modal', function() {
        setTimeout(function() {
            $('.modal-backdrop').addClass('my-backdrop');
        });
    });

    $('#Left-Nav ul li').on('click', function() {
        $('.sidenav-overlay').removeClass('active');
        $('#Left-Nav').removeClass('active');
    });

    // $('.sidenav-overlay').on('click', function() {
    //     $(this).removeClass('active');
    //     $('#Left-Nav').removeClass('active');
    // });

    // TOGGLE DRAWER
    // $('#tglDrawMob').click(function() {
    //     $('.Main_Tag').stop().toggleClass('Main_Tag_Active');
    //     $("#Left-Nav").stop().toggleClass("active");
    //     $('.sidenav-overlay').toggleClass('active');
    // });

    // $('#tglDrawDesk').click(function() {
    //     $("#Left-Nav").stop().toggleClass("is-active");
    //     $('.Main_Tag').stop().toggleClass('Main_Tag_Active');
    // });
    // IMAGE UPLOAD and preview
    // document.getElementById("chooseFile").onchange = function(e) {
    //     var numFiles = $("input:file")[0].files.length;
    //     $(".file-select").html('<small>more</small>' + numFiles);
    //     var filename = $("#chooseFile").val()

    //     if (/^\s*$/.test(filename)) {
    //         $(".file-upload").removeClass('active');
    //         $("#noFile").text("No file chosen...");
    //     } else {
    //         $(".file-upload").addClass('active');
    //         $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
    //     }

    //     $('.avatar-preview').css('display', 'flex');
    //     var files = e.target.files;
    //     for (var i = 0; i < files.length; i++) {
    //         var f = files[i];
    //         var srcView = $('.avatar-preview');
    //         var sourceVid = document.createElement('video');
    //         var srcImg = document.createElement('img');
    //         var reader = new FileReader();
    //         var srcItem = $(document.createElement('div')).addClass('src-items');
    //         if (f.type.match('video.*')) {
    //             // sourceVid.width = 150;
    //             sourceVid.controls = true;
    //             sourceVid.classList.add('isa-source-style');
    //             sourceVid.src = URL.createObjectURL(files[i]);
    //             srcItem.append(sourceVid);
    //         }
    //         if (f.type.match('image.*')) {
    //             // sourceImg.height = 100;
    //             srcImg.classList.add('isa-source-style');
    //             srcImg.controls = true;
    //             srcImg.src = URL.createObjectURL(files[i]);
    //             srcItem.append(srcImg);
    //         }
    //         reader.onloadend = function() {
    //             sourceVid.src = reader.result;
    //             srcImg.src = reader.result;
    //         }
    //         srcView.append(srcItem);
    //         $(srcItem).find('.closeItem').click(function() {
    //             $(this).parent('.src-items').remove();
    //         });
    //     }
    //    }
});